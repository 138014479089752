// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-homepage-certificates-jsx": () => import("./../../../src/pages/Homepage/Certificates.jsx" /* webpackChunkName: "component---src-pages-homepage-certificates-jsx" */),
  "component---src-pages-homepage-connect-jsx": () => import("./../../../src/pages/Homepage/Connect.jsx" /* webpackChunkName: "component---src-pages-homepage-connect-jsx" */),
  "component---src-pages-homepage-introductions-jsx": () => import("./../../../src/pages/Homepage/Introductions.jsx" /* webpackChunkName: "component---src-pages-homepage-introductions-jsx" */),
  "component---src-pages-homepage-projects-jsx": () => import("./../../../src/pages/Homepage/Projects.jsx" /* webpackChunkName: "component---src-pages-homepage-projects-jsx" */),
  "component---src-pages-homepage-skills-jsx": () => import("./../../../src/pages/Homepage/Skills.jsx" /* webpackChunkName: "component---src-pages-homepage-skills-jsx" */),
  "component---src-pages-homepage-work-experience-experience-item-jsx": () => import("./../../../src/pages/Homepage/WorkExperience/ExperienceItem.jsx" /* webpackChunkName: "component---src-pages-homepage-work-experience-experience-item-jsx" */),
  "component---src-pages-homepage-work-experience-index-jsx": () => import("./../../../src/pages/Homepage/WorkExperience/index.jsx" /* webpackChunkName: "component---src-pages-homepage-work-experience-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */)
}

